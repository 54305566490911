import { AxiosRequestConfig } from 'axios';
import { Dispatch } from '../../apps/store';
import { Events } from '../Events';
import { HttpApi } from '../https';

export interface SimpleRestModel2Options<CustomBaseApiPathPayload> {
  name: string;
  getBaseApiPath?: (payload: SimpleRestModel2ApiPathPayload & CustomBaseApiPathPayload) => string;
  getApiPath?: any;
}

export interface SimpleRestModel2State<ModelType = any> {
  items: ModelType[];
  itemsById: {
    [key: string]: ModelType;
  };
  errors: any;
  loading: {
    global: boolean;
    // all effects
    loadCollection: boolean;
  };
}

export interface SimpleRestModel2ApiPathPayload {
  id?: string | number;
  action?: string;
}
export type SimpleRestModel2RequestPayload = AxiosRequestConfig;

export const SimpleRestModel2 = <ModelType = any, CustomBaseApiPathPayload = {}>(
  options: SimpleRestModel2Options<CustomBaseApiPathPayload>,
) => {
  const _getApiPath = (
    payload: SimpleRestModel2ApiPathPayload & CustomBaseApiPathPayload,
    action: string,
  ) => {
    const baseApiPath = options.getBaseApiPath
      ? options.getBaseApiPath(payload)
      : `/${options.name}/`;

    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return baseApiPath;
      case 'loadItem':
      case 'updateItem':
      case 'deleteItem':
        return `${baseApiPath}${payload.id}/`;
      case 'customCollectionAction':
        return `${baseApiPath}${payload.action ? `${payload.action}/` : ''}`;
      case 'customItemAction':
        return `${baseApiPath}${payload.id}/${payload.action}/`;
    }
  };

  return {
    name: options.name,
    state: {
      items: [],
      itemsById: {},
      loading: {
        loadCollection: false,
      },
    },
    reducers: {
      setState: (
        state: SimpleRestModel2State<ModelType>,
        payload: Partial<SimpleRestModel2State<ModelType>>,
      ) => ({
        ...state,
        ...payload,
      }),
      setLoading: (
        state: SimpleRestModel2State<ModelType>,
        actionName: string,
        loadingState: boolean,
      ) => {
        return {
          ...state,
          loading: {
            ...state.loading,
            [actionName]: loadingState,
          },
        };
      },
    },
    effects: (dispatch: Dispatch) => ({
      loadCollection(
        payload: SimpleRestModel2ApiPathPayload &
          SimpleRestModel2RequestPayload &
          CustomBaseApiPathPayload,
      ) {
        dispatch[options.name].setLoading('loadCollection', true);
        Events.emit(`Model.${options.name}.loadCollection.loading`);
        const asyncAction = HttpApi.get(_getApiPath(payload, 'loadCollection'), payload);

        asyncAction
          .then((data) => {
            console.warn('then, data', data);
            dispatch[options.name].setState({
              items: data,
            });
            Events.emit(`Model.${options.name}.loadCollection.success`);
          })
          .catch((error) => {
            Events.emit(`Model.${options.name}.loadCollection.error`);
          })
          .finally(() => {
            dispatch[options.name].setLoading('loadCollection', false);
          });
        return asyncAction;
      },
    }),
  };
};
