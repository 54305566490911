import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { BasketMealsCount } from './components/BasketMealsCount';

const App: React.FC = (props) => {
  return (
    <Provider store={store}>
      <div className="App">{props.children}</div>
    </Provider>
  );
};
const container = document.querySelector('#basket_meal_counter');
ReactDOM.render(
  <App>
    <BasketMealsCount />
  </App>,
  container,
);
