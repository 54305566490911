import { basket } from './basket';
import { meals } from './meals';
import { customerContacts } from './customer-contacts';
import { customerFinancial } from './customer-financial';
import { customerBasicData } from './customer-basic-data';
import { allowedPayments } from './allowed-payments';
import { customerOrders } from './customer-orders';
import { searchMealsData } from './search-meals-data';
import { shop } from './shop';

// no need to extend from Models
export interface RootModel {
  basket: typeof basket;
  meals: typeof meals;
  customerContacts: typeof customerContacts;
  customerFinancial: typeof customerFinancial;
  customerBasicData: typeof customerBasicData;
  allowedPayments: typeof allowedPayments;
  customerOrders: typeof customerOrders;
  searchMealsData: typeof searchMealsData;
  shop: typeof shop;
}

export const models: RootModel = {
  basket,
  meals,
  customerContacts,
  customerFinancial,
  customerBasicData,
  allowedPayments,
  customerOrders,
  searchMealsData,
  shop,
};
