import { Dispatch } from '..';
import { HttpApi } from '../../../common/https';
import { BasketState } from './basket.model';
import { EventsActions } from '../../../common/Events';

interface ExtrasState {
  id: number;
  count: number;
}

export interface DeliveryAddressPayload {
  street: string; //  ulica
  houseNumber: string; //  numer mieszkania
  postalCode: string; //  kod pocztowy
  city: string; //  kod pocztowy
  lon: string; //  LON adresu
  lat: string; //  LAT adresu
  isMain?: boolean;
}

export const basket = {
  name: 'basket',
  state: {},
  reducers: {
    setState: (state: BasketState, payload: BasketState | null): Partial<BasketState> => ({
      ...payload,
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async getBasket() {
      const asynAction = HttpApi.get(`api/v1/shop/order/details/`);
      asynAction
        .then((response) => {
          dispatch.basket.setState(response.data);
        })
        .catch(() => {
          dispatch.basket.setState(null);
        });

      return asynAction;
    },
    async addMeal(payload: {
      mealId: number;
      mealCount: number;
      note: string;
      cartId?: string;
      setId?: string;
      extras?: ExtrasState[][];
    }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/addMeal/`, {
        meal_id: Number(payload.mealId),
        meal_count: payload.mealCount,
        order_id: payload.cartId,
        note: payload.note,
        set_id: payload.setId,
        extras: payload.extras,
      });
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
        window['ev'].emit(EventsActions.BASKET_UPDATE, {
          basket: response.data.basket,
        });
      });

      return asynAction;
    },
    async updateEmailPhone(payload: { phone: string; email: string }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/emailPhone/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });

      return asynAction;
    },
    // async updateInvoiceData(payload: {
    //   vatNumber: string; // NIP firmy
    //   addressCity: string; // Miasto
    //   addressPostalCode: string; // Kod pocztowy firmy
    //   addressData: string; // Ulica. Nr domu/ lokalu
    //   contactEmail: string; // Email na który zostanie wysłana faktura
    //   notes: string; // Notatki: value,
    // })
    async updateInvoiceData(payload: { companyId: any; withInvoice: boolean }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/invoiceData/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });
      return asynAction;
    },
    async removeInvoiceData() {
      const asynAction = HttpApi.delete(`api/v1/shop/order/invoiceData/`);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });
      return asynAction;
    },
    async setNotes(payload: { orderNotes: string }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/setNotes/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });
      return asynAction;
    },
    async setAddressNotes(payload: { details: string }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/setAddressDetails/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });
      return asynAction;
    },
    async updateDeliveryTime(payload: { customerTimeSet: boolean; deliveryDate: string }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/customerTimeSet/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });
      return asynAction;
    },
    async directPickUp(
      payload: {
        directPickUp: boolean;
        dontUpdateBasketState?: boolean;
      },
      options?: {},
    ) {
      const asynAction = HttpApi.post(`api/v1/shop/order/directPickUp/`, payload);
      asynAction.then((response) => {
        if (payload?.dontUpdateBasketState) {
          return;
        }
        dispatch.basket.setState(response.data);
      });
      return asynAction;
    },
    async payByPoints(payload: { payByPoints: boolean }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/payByPoints/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });
      return asynAction;
    },
    async updateDeliveryAddress(payload: { address: DeliveryAddressPayload }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/deliveryAddress/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });

      return asynAction;
    },
    async removeBasket(payload: { basketId: string }) {
      const asynAction = HttpApi.delete(`api/v1/shop/order/delete/`);
      asynAction.then((response) => {
        dispatch.basket.setState(null);
      });

      return asynAction;
    },
    async updateDriverExtraBonus(payload: { driverExtraBonus: number }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/driverExtraBonus/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });

      return asynAction;
    },
    async setPaymentType(payload: { paymentType: number }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/setPayment/`, payload);
      asynAction.then((response) => {
        dispatch.basket.setState(response.data);
      });

      return asynAction;
    },
    async checkOrderData() {
      const asynAction = HttpApi.get(`api/v1/shop/order/checkOrderData/`);

      asynAction.then((response) => {
        dispatch.basket.setState(response.data ?? null);
      });

      return asynAction;
    },
  }),
};
