import { HttpApi } from '../../../common/https';
import { Dispatch } from '../index';

export enum PaymentTypeType {
  Gotowka = 0,
  Platnosc_online_payu = 10,
  Platnosc_odroczona_przelew = 20,
}

export enum PaymentTypeVisbility {
  OnlineAndDriver = 0,
  OnlyWWW = 10,
  OnlyDriver = 20,
}

export interface AllowedPayment {
  id: number;
  position: number;
  namePl: string;
  nameEn: string;
  displayedName: string;
  icon: string | null;
  paymentType: PaymentTypeType;
  paymentVisibility: PaymentTypeVisbility;
}

export type AllowedPayments = AllowedPayment[];

export const allowedPayments = {
  name: 'allowedPayments',
  state: {
    allowedPayments: [],
  },
  reducers: {
    setState: (
      state: AllowedPayments,
      payload: AllowedPayments | null,
    ): AllowedPayments | null => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async getData() {
      const asyncAction = HttpApi.get('api/v1/shop/allowedPayments/');
      asyncAction
        .then((response) => {
          dispatch.allowedPayments.setState({
            allowedPayments: response.data.results,
          });
        })
        .catch(() => dispatch.allowedPayments.setState(null));

      return asyncAction;
    },
  }),
};
