import { HttpApi } from '../../../common/https';
import { Dispatch } from '../index';

export interface CustomerOrder {
  id: string; // id
  deliveryDate: string; // Data dostarczenia
  withInvoice: boolean; // Flaga czy zamówienie buło z fakturą
  companyName: string; // nullable Nazwa firmy na fakturze
  restaurantName: string; // nullable Nazwa restauracji
  gross: number; // Wartość zamówienia w groszach
  driverExtraBonus: number; // Napiwek dla kierowcy
}

export interface CustomerOrdersState {
  count: number;
  items: CustomerOrder[];
}

export const customerOrders = {
  name: 'customerOrders',
  state: {},
  reducers: {
    setState: (
      state: CustomerOrdersState,
      payload: CustomerOrdersState | null,
    ): CustomerOrdersState | null => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async getData() {
      const asyncAction = HttpApi.get('api/v1/shop/customer/Orders/');
      asyncAction
        .then((response) => {
          dispatch.customerOrders.setState(response.data);
        })
        .catch(() => dispatch.customerOrders.setState(null));

      return asyncAction;
    },
    async getOrderDetails(payload: { order_id: string }) {
      const asynAction = HttpApi.post(`api/v1/shop/order/details/`, payload);
      // asynAction
      //   .then((response) => {
      //     dispatch.customerOrders.setState(response.data);
      //   })
      //   .catch(() => {
      //     dispatch.customerOrders.setState(null);
      //   });

      return asynAction;
    },
  }),
};
