import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BasketState } from '../store/models/basket.model';
import { EventsActions } from '../../common/Events';

export interface BasketMealsCountProps {}

export const BasketMealsCount: React.FC<BasketMealsCountProps> = (props) => {
  const basketState: BasketState = useSelector((state) => state.basket);
  const [mealsCount, setMealsCount] = useState(0);

  useEffect(() => {
    window['ev'].on(EventsActions.BASKET_UPDATE, (data) => {
      setMealsCount(data.basket.mealsCount);
    });

    return () => {
      window['ev'].off(EventsActions.BASKET_UPDATE, true);
    };
  }, []);

  useEffect(() => {
    setMealsCount(basketState.basket?.mealsCount || 0);
  }, [basketState]);

  if (mealsCount > 0) {
    return (
      <div className="mealCount">
        <span className="absolute right-0 top-0 mt-2 inline-block h-6 w-6 rounded-full bg-red text-center text-white">
          {mealsCount}
        </span>
      </div>
    );
  } else {
    return null;
  }
};
