import { HttpApi } from '../../../common/https';
import { Dispatch } from '../index';
import queryString from 'query-string';

export interface MealImage {
  url: string;
}

export interface DeliveryInfo {
  cost: number;
  distance: number;
  time: number;
}

export interface CuisineType {
  id: number;
  namePl: string;
  nameEn: string;
}

export interface Meal {
  id: number;
  restaurantId: number;
  image: MealImage;
  index: string;
  likes: number;
  namePl: string;
  nameEn: string;
  descriptionPl: string;
  descriptionEn: string;
  price: number;
  isAlcohol: boolean;
  isBeverage: boolean;
  isGlutenFree: boolean;
  isLikedByUser: boolean;
  isLunch: boolean;
  isNew: boolean;
  isVegan: boolean;
  isVegetarian: boolean;
  spicyLevel: number;
}

export interface Address {
  addressCity: string;
  addressData: string;
}

export interface RestaurantOpeningHours {
  dayNamePL: string;
  dayNameEN: string;
  isActive: boolean;
  openingHours: string;
}

export interface Restaurant {
  name: string;
  slug: string;
  descriptionPl: string;
  descriptionEn: string;
  cuisineTypes: CuisineType[];
  meals: Meal[];
  likes: number;
  isLikedByUser: boolean | null;
  packagePrice: number;
  deliveryInfo: DeliveryInfo;
  logo: string;
  image: string;
  address: Address;
  restaurantOpeningHours: RestaurantOpeningHours[];
}

export interface SearchMealsData {
  searchFor: string;
  cuisineTypes: any;
  resultsLoading: boolean;
  results: Restaurant[];
}

export const searchMealsData = {
  name: 'searchMealsData',
  state: {
    searchFor: '',
    cuisineTypes: [],
    resultsLoading: false,
    results: [],
  },
  reducers: {
    setState: (
      state: SearchMealsData,
      payload: SearchMealsData | null,
    ): SearchMealsData | null => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async getData() {
      dispatch.searchMealsData.setState({
        resultsLoading: true,
      });
      let params = queryString.parse(window.location.search);
      const asyncAction = HttpApi.get('api/v1/shop/search/', {
        params: {
          cuisine_types: params.cuisine_types,
          order_by: params.order_by,
          search: params.search,
        },
      });
      asyncAction
        .then((response) => {
          dispatch.searchMealsData.setState({
            results: response.data.results,
            resultsLoading: false,
          });
        })
        .catch(() => dispatch.searchMealsData.setState(null));

      return asyncAction;
    },
    async getCuisineTypes() {
      let params = queryString.parse(window.location.search);
      const asyncAction = HttpApi.get('api/v1/shop/cuisine-types/');
      asyncAction
        .then((response) => {
          dispatch.searchMealsData.setState({
            cuisineTypes: response.data,
          });
        })
        .catch(() =>
          dispatch.searchMealsData.setState({
            cuisineTypes: [],
          }),
        );

      return asyncAction;
    },
  }),
};
