/*
  const callback = () => {}
  Events.on('addFavoriteTeam', callback)
  Events.off('addFavoriteTeam', callback);

  Events.on(
    [
      'addFavoriteTeam',
      'removeFavoriteTeam',
    ],
    (data) => {
      // do something width data
    },
  );
  Events.off('addFavoriteTeam', true);

  Events.emit('addFavoriteTeam', data);
*/

const events = {};
const subscribers = {};

export const Events = {
  on: (eventNames, fn) => {
    let _eventNames = eventNames;
    if (!Array.isArray(eventNames)) {
      _eventNames = [eventNames];
    }

    _eventNames.forEach((event) => {
      events[event] = events[event] || [];
      events[event].push(fn);
    });
  },
  off: (eventNames, fn) => {
    let _eventNames = eventNames;
    if (!Array.isArray(eventNames)) {
      _eventNames = [eventNames];
    }

    _eventNames.forEach((eventName) => {
      if (events[eventName]) {
        for (let i = 0; i < events[eventName].length; i += 1) {
          if (events[eventName][i] === fn || fn === true) {
            events[eventName].splice(i, 1);
            break;
          }
        }
      }
    });
  },
  emit: (eventName, data?: any) => {
    // console.log(`:: EVENTS.emit ::: ${eventName}`);
    if (events[eventName]) {
      events[eventName].forEach((fn) => {
        fn(data);
      });
    }
  },
  subscribe: (eventName, fn) => {
    const callback = () => {
      fn();
    };
    return () => {
      if (subscribers[eventName]) {
        for (let i = 0; i < subscribers[eventName].length; i += 1) {
          if (subscribers[eventName][i] === callback) {
            subscribers[eventName].splice(i, 1);
            break;
          }
        }
      }
    };
  },
};

window['ev'] = window['ev'] || Events;

export enum EventsActions {
  ADD_MEAL_TO_BASKET = 'ADD_MEAL_TO_BASKET',
  SHOW_LIKE_MODAL_FOR_ANONYMOUS = 'SHOW_LIKE_MODAL_FOR_ANONYMOUS',
  RESTAURANT_SEARCH_FILTER_CLICK = 'RESTAURANT_SEARCH_FILTER_CLICK',
  BASKET_UPDATE = 'BASKET_UPDATE',
  HOME_SHOW_DIRECT_PICKUP_DIALOG = 'HOME_SHOW_DIRECT_PICKUP_DIALOG',
  HOME_SET_PICKUP_TO_DELIVERY = 'HOME_SET_PICKUP_TO_DELIVERY',
  HOME_DISPLAY_ADDRESSES_DIALOG = 'HOME_DISPLAY_ADDRESSES_DIALOG',
}
