export interface AppConfig {
  apiUrl: string;
  languageCode: string;
  isLoggedIn: boolean;
}

declare var APP_CONFIG: AppConfig;

export const config = () => {
  return {
    apiUrl: APP_CONFIG.apiUrl,
    languageCode: APP_CONFIG.languageCode,
    isLoggedIn: APP_CONFIG.isLoggedIn,
    staticImagesBaseUrl: '/static/',
    DateTimeFormat: 'YYYY.MM.DD HH:mm',
    DateFormat: 'YYYY.MM.DD',
    TimeFormat: 'HH:mm',
    MinuteStep: 15,
    StartTime: '12:00',
    EndTime: '21:00',
  };
};
