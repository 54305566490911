// import axios from 'axios';
import { config } from '../../config';
import { getCookieValue } from './getCookieValue';

const axios = require('axios').default;

export interface httpClientOptions {
  // extends AxiosRequestConfig {
  localStorageKeyWithToken?: string;
  onUnauthorized?: Function;
  debug?: boolean;
}

export interface httpClientRequestOptions {
  // extends AxiosRequestConfig {
  ommitToken?: boolean;
  debug?: boolean;
}

export const axiosInstance = (options) => {
  const instance = axios.create({
    validateStatus: (status: number) => {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
    ...options,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    withCredentials: true,
  });

  // instance.interceptors.request.use((config) => {
  //   return config;
  // });

  instance.interceptors.response.use(
    (response) => {
      if (response.status === 401 && options.onUnauthorized) {
        options.onUnauthorized(response);
      }

      if (response.status >= 300) {
        return Promise.reject(response);
      }

      return response;
    },
    (error) => {
      // Do something with response error
      return Promise.reject(error);
    },
  );

  return instance;
};

export const HttpApi = axiosInstance({
  baseURL: config().apiUrl,
  onUnauthorized: (res) => {
    // window.location.href = "/accounts/login";
  },
});
