import { SimpleRestModel2 } from '../../../common/SimpleRestModel/SimpleRestModel2';

export interface MealModel {
  id: number;
  namePl: string;
}

export const meals = SimpleRestModel2<MealModel, { restaurantId: string }>({
  name: 'meals',
  getBaseApiPath: ({ restaurantId }) => `restaurants/${restaurantId}/meals/`,
});
