import { HttpApi } from '../../../common/https';
import { Dispatch } from '../index';

export interface CustomerBasicData {
  id: number;
  notes: string;
  balance: number;
  balanceNotes: string | null;
  discount: number;
  points: number;
  marketingPermission: boolean;
  smsContact: boolean;
  emailContact: boolean;
  phoneContact: boolean;
  moneyTransfer: boolean;
  user: {
    email: string;
    lastLogin: string;
    name: string;
    username: string;
  };
}

export const customerBasicData = {
  name: 'customerBasicData',
  state: {},
  reducers: {
    setState: (
      state: CustomerBasicData,
      payload: CustomerBasicData | null,
    ): CustomerBasicData | null => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async getData() {
      const asyncAction = HttpApi.get('api/v1/shop/customer/BasicData/');
      asyncAction
        .then((response) => {
          dispatch.customerBasicData.setState(response.data);
        })
        .catch(() => dispatch.customerBasicData.setState(null));

      return asyncAction;
    },
    async updateData(payload: { email: string; name: string }) {
      const asyncAction = HttpApi.post('api/v1/shop/user/changeData/', payload);
      asyncAction
        .then((response) => {
          dispatch.customerBasicData.setState(response.data.customer);
        })
        .catch(() => dispatch.customerBasicData.setState(null));

      return asyncAction;
    },
  }),
};
