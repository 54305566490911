import { HttpApi } from '../../../common/https';
import { Dispatch } from '../index';

export interface CustomerContactPhone {
  id: number;
  phone: string;
  isMain: boolean;
}

export interface CustomerContactAddress {
  id: number;
  deliveryCost?: number | null;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  lon: string;
  lat: string;
  details: string;
  notes?: string;
}

export interface CustomerContactsState {
  orderPhones: CustomerContactPhone[];
  orderAddresses: CustomerContactAddress[];
}

export type ContactType = 'address' | 'phone';

export const customerContacts = {
  name: 'customerContacts',
  state: {},
  reducers: {
    setState: (
      state: CustomerContactsState,
      payload: CustomerContactsState | null,
    ): CustomerContactsState | null => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async getData() {
      const asyncAction = HttpApi.get('api/v1/shop/customer/ContactsData/');
      asyncAction
        .then((response) => {
          dispatch.customerContacts.setState(response.data);
        })
        .catch(() => dispatch.customerContacts.setState(null));

      return asyncAction;
    },
    async addPhone(payload: { phone: string; isMain: boolean }) {
      const asyncAction = HttpApi.post('api/v1/shop/customer/ContactsData/', {
        contactType: 'phone',
        phone: payload,
      });
      asyncAction.then((response) => {
        dispatch.customerContacts.setState(response.data);
      });
      return asyncAction;
    },
    async updatePhone(payload: { id: number; phone: string; isMain: boolean }) {
      const asyncAction = HttpApi.patch('api/v1/shop/customer/ContactsData/', {
        contactType: 'phone',
        phone: payload,
      });
      asyncAction.then((response) => {
        dispatch.customerContacts.setState(response.data);
      });
      return asyncAction;
    },
    async deletePhone(payload: { id: string }) {
      const asyncAction = HttpApi.delete('api/v1/shop/customer/ContactsData/', {
        data: {
          contactType: 'phone',
          phone: payload,
        },
      });
      asyncAction.then((response) => {
        dispatch.customerContacts.setState(response.data);
      });
      return asyncAction;
    },
    async addAddress(payload: Partial<CustomerContactAddress>) {
      const asyncAction = HttpApi.post('api/v1/shop/customer/ContactsData/', {
        contactType: 'address',
        address: payload,
      });
      asyncAction.then((response) => {
        dispatch.customerContacts.setState(response.data);
      });
      return asyncAction;
    },
    async updateAddress(payload: Partial<CustomerContactAddress>) {
      const asyncAction = HttpApi.patch('api/v1/shop/customer/ContactsData/', {
        contactType: 'address',
        address: payload,
      });
      asyncAction.then((response) => {
        dispatch.customerContacts.setState(response.data);
      });
      return asyncAction;
    },
    async deleteAddress(payload: { id: string }) {
      const asyncAction = HttpApi.delete('api/v1/shop/customer/ContactsData/', {
        data: {
          contactType: 'address',
          address: payload,
        },
      });
      asyncAction.then((response) => {
        dispatch.customerContacts.setState(response.data);
      });
      return asyncAction;
    },
  }),
};
