import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import createRematchPersist from '@rematch/persist';

import { models, RootModel } from './models';

const persistPlugin = createRematchPersist({
  whitelist: ['basket'],
  throttle: 10,
  version: 1,
});

export const store = init({
  models,
  plugins: [persistPlugin],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type iRootState = RematchRootState<RootModel>;

// @ts-ignore
window.ss = store;
