import { HttpApi } from '../../../common/https';
import { Dispatch } from '../index';

export interface CustomerFinancialData {
  id: number; // id firmy
  vatNumber: string; // NIP
  companyName: string; // Nazwa firmy
  addressCity: string; // Miasto
  addressPostalCode: string; // Kod pocztowy
  addressData: string; // Ulica, numer budynku/lokalu
  contactEmail: string; // email kontaktowy do wysłania faktury
  notes: string; // notatki
}

export type CustomerFinancialState = CustomerFinancialData[];

export const customerFinancial = {
  name: 'customerFinancial',
  state: {
    customerFinancialData: [],
  },
  reducers: {
    setState: (
      state: CustomerFinancialState,
      payload: CustomerFinancialState | null,
    ): CustomerFinancialState | null => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async getData() {
      const asyncAction = HttpApi.get('api/v1/shop/customer/FinancialData/');
      asyncAction
        .then((response) => {
          dispatch.customerFinancial.setState({
            customerFinancialData: response.data,
          });
        })
        .catch(() => dispatch.customerFinancial.setState(null));

      return asyncAction;
    },
    async addCompany(payload: Omit<CustomerFinancialData, 'id'>) {
      const asyncAction = HttpApi.post('api/v1/shop/customer/FinancialData/', payload);
      asyncAction
        .then((response) => {
          dispatch.customerFinancial.setState({
            customerFinancialData: response.data,
          });
        })
        .catch(() => dispatch.customerFinancial.setState(null));

      return asyncAction;
    },
    async updateCompany(payload: CustomerFinancialData) {
      const asyncAction = HttpApi.patch(
        `api/v1/shop/customer/FinancialData/${payload.id}/`,
        payload,
      );
      asyncAction
        .then((response) => {
          dispatch.customerFinancial.setState({
            customerFinancialData: response.data,
          });
        })
        .catch(() => dispatch.customerFinancial.setState(null));

      return asyncAction;
    },
    async deleteCompany(payload: { id: number }) {
      const asyncAction = HttpApi.delete(`api/v1/shop/customer/FinancialData/${payload.id}/`);
      asyncAction
        .then((response) => {
          dispatch.customerFinancial.setState({
            customerFinancialData: response.data,
          });
        })
        .catch(() => dispatch.customerFinancial.setState(null));

      return asyncAction;
    },
  }),
};
