import { Dispatch } from 'src/store';
import { HttpApi } from '../../../common/https';

export interface ShopModel {
  restaurantMenu: any;
}

export const shop = {
  name: 'shop',
  state: {},
  reducers: {
    setState: (state: ShopModel, payload: ShopModel | null): ShopModel | null => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async getRestaurantMenu(restaurantId: string | number) {
      if (restaurantId) {
        const asyncAction = HttpApi.get(`api/v1/shop/restaurant-menu/${restaurantId}`);
        asyncAction
          .then((response) => {
            dispatch.shop.setState({
              restaurantMenu: response.data,
            });
          })
          .catch(() =>
            dispatch.shop.setState({
              restaurantMenu: null,
            }),
          );

        return asyncAction;
      } else {
        return null;
      }
      
    },
  }),
};
